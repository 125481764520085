<template>
  <div class="application">
    <v-app>
      <v-app-bar app flat color="white">
        <v-container>
          <v-toolbar flat>
            <v-toolbar-title class="text-capitalize title font-weight-bold"
              >BI Portal</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn text class="text-capitalize font-weight-bold">
                Document
              </v-btn>
              <v-btn text class="text-capitalize font-weight-bold">
                Tutorial Video
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-container>
      </v-app-bar>
      <v-divider></v-divider>
      <v-main>
        <v-layout row wrap justify-space-around>
          <v-flex md2>
            <LandPageIcon />
          </v-flex>
          <v-flex md4 align-self-center>
            <v-card-title class="font-weight-medium display-2">
              Want to Create Cool Insights?</v-card-title
            >
            <v-card-actions>
              <!-- <v-btn
                class="ma-2 pa-2 text-capitalize"
                width="120px"
                depressed
                color="primary"
                @click="openCreateReport()"
              >
                Create Report
              </v-btn> -->
              <v-dialog v-model="dialog" width="900" persistent>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ma-2 pa-2 text-capitalize"
                    width="120px"
                    outlined
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    View Demo
                  </v-btn>
                </template>
                <v-card class="px-2 pt-2 pt-1">
                  <v-toolbar>
                    <v-spacer></v-spacer>
                    <v-icon @click="dialog = false" style="color: pink"
                      >mdi-close</v-icon
                    ></v-toolbar
                  >
                  <iframe
                    v-if="dialog"
                    :src="videoUrl"
                    onload='javascript:(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+"px";}(this));'
                    style="
                      height: 500px;
                      width: 100%;
                      border: none;
                      overflow: hidden;
                    "
                  ></iframe>
                </v-card>
              </v-dialog>
            </v-card-actions>
          </v-flex>
        </v-layout>
        <createReportDialog
          v-if="createReportDialog"
          :createReportDialog="createReportDialog"
          @closeCreateDialog="closeCreateDialog"
        />
      </v-main>
    </v-app>
  </div>
</template>
<script>
import LandPageIcon from '../../assets/IllustrationsLanding_Page.svg'

export default {
  name: 'Home',
  components: {
    LandPageIcon,
    createReportDialog: () =>
      import('../../components/ReportBuilder/createReportDialog.vue')
  },
  data() {
    return {
      videoUrl: 'https://www.youtube.com/embed/tgbNymZ7vqY',
      dialog: false,
      createReportDialog: false
    }
  },
  methods: {
    openCreateReport() {
      this.createReportDialog = true
      // this.$store.dispatch('setNewReportConfig')
      // this.$router.push('/compose-report')
    },
    closeCreateDialog() {
      console.log('close====')
      this.createReportDialog = false
    }
  }
}
</script>
<style lang="scss" scoped>
.application {
  font-family: $body-font-family;
}
.title {
  color: #072f5f;
}
</style>

